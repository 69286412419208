<template>
  <div>
      <b-container fluid class="d-flex justify-content-center align-items-center mt-5">
          <b-row>
              <b-col>
                  <b-card style="max-width: 30rem; background-color: #F5F5F7; color: #6E6E73; font-family: 'Allison', cursive; font-weight: 400;">
                      <!-- Título personalizado centrado -->
                      <template v-slot:header>
                          <div class="text-center" style="font-size: 42px;">{{subtit1}}</div>
                      </template>
                      <b-card-text style="font-size: 18px; font-family: 'Cormorant', cursive;">
                          
                          <span v-html="iniciotxt1"></span>
                          <br><br>
                          <h3 class="text-center" style="font-family: 'Allison', cursive; font-size: 35px;">Contacto Obrador</h3>

                          <div class="text-center" style="font-family: 'Cormorant', cursive;">Ildepontso Zarraga, 1<br>
                          48100 Mungia (Bizkaia)<br>
                          <span>{{telefono}}</span><br></div>

                      </b-card-text>
                      
                      <!-- <b-button href="#" variant="primary">Ir a algún lugar</b-button> -->
                  </b-card>
              </b-col>
          </b-row>
      </b-container>
  </div>
</template>

<script>
    import store from '@/store.js'
    import {traduccion} from "@/traducciones.js"

    export default {
        data() {
            return {
                
            };
        },
        computed: {
            iniciotxt1() {
              return traduccion[store.language].iniciotxt1;
            },
            subtit1() {
              return traduccion[store.language].subtit1;
            },
            telefono() {
              return traduccion[store.language].telefono;
            }
        }
    }
</script>


export const traduccion = {
    eus: {
          subtit1: "Ogigintza Tradizioa",
          menu1: "Ataria",
          menu2: "Historia",
          menu3: "Zerbitzuak",
          menu4: "Produktuak",
          menu5: "Kontaktua",
          menu6: "Aginduak",
          historia1: "Kosmeren Semeak okindegi sektoreko familia enpresa da. Enpresa honek denbora luzez iraun du eta belaunaldiz belaunaldi eskualdatzen joan da Mungiako Elgezabal familiaren baitan.<br><br>1931 urtean Kosme Elgezabal okin-laguntzaile gisa hazi zen lanean. Horrela urteak joan ziren eta okin maisu gisa bermatu ahala, bere enpresa propioa sortzeari ekin zion. Denbora igaro ahala, sortu berri zuen enpresan, bere metodologia inplementatzen joan zen, metodologia hori, familiaren barrenean lehenengo semeei eta beranduago ilobei trasmitituko zitzaielarik.<br><br>Hasieratik gure lan egiteko era, okindegian ogia egin eta gero ingurutik banatzea zen, lehenengo Mungia eta aldameneko inguruan eta heina handituz zioan bitartean Bizkaiako beste lekuetara. Honela gure enpresa hedatzen joan zen, Bizkaiako zenbait herritan okindegiak zabaldu genituen eta Eleizalde gozotegia sortu genuen, horrela gure produktuen eskaintza handituz.<br><br>Kosmeren Semeak eskaintzen duen ogiaren ospe handiak, jendearen ahotan iraun du produktu nabarmendun bat izateagatik. Gure ekoizpenaren ospea, gure okin maisuek artisau-eran ekoizteagatik finkatu da, ama-masa, ura, gatza, legamia eta kalitatezko urunak erabilita.<br><br>Guzti hau dela eta, Kosmeren Semeak gaur egun dena bilakatu da. Bizkaiako eremu zabal batean erreferente den okindegi eta gozotegi enpresa.",
          servicios1: "Kosmeren Semeak, ogigintza munduko enpresa bat da eta gure zerbitzuak Bizkaiako lurraldean ahalik eta hoberen eskaini ahal izateko, ogitegi ezberdinez eta gure furgoneta flotaz baliatzen gara.<br><br><b>Banakakoentzat:</b><br>Gure banaketa eremuan, familiabakar etxebizitzan edo baserrian bizi diren gure bezeroentzat, atez-ateko zerbitzua dugu. Horrela egunero guk eramango dizuegu ogia etxera.<br><br><b>Enpresentzat:</b><br>Gaur egun, gure zerbitzuez gozatzen duten enpresak gero eta gehiago dira: jatetxe eta tabernak, katering enpresak, denda ezberdinak, eskola-jantokiak, eta abar.<br>Ez eduki inolako zalantzarik gurekin kontaktuan jartzeko eta zure enpresaren beharrizanakahal eta hobeen betetzeko aukera bilatuko dugu.<br><br> <b>Eleizalde Gozotegia:</b><br>Gure eskaintza eta zerbitzua erabatekoa izateko ideiarekin, Eleizalde Gozotegiarekin elkarlanean diardu. Horrela, gure artisau ideologia mantenduz, gozotegi esparruko produktu ugari ere eskaintzan ditugu: pastelak eta pastak, tartak, eta abar.<br>Denboraldiko zenbait produktu ere eskaintzen dira: gabon trufak, turroiak, erregetako erroskoak, San Blas opilak, inauteri tostadak, kauserak, Domu Santuko santu hezurrak, eta abar.<br><br><b>Gure produktuak saldu nahi dituzu?</b><br>Gure pruduktuak zuen saltokian saltzeko aukera ere eskaintzen dizugu. Gaur egun, okindegi, supermerkatu, denda edo bestelako negozioak hornitzen ditugu.<br>Hornikuntza produktu burutuan edo aurregosi formatuan (behar izatekotan labea utziko dizuegu) egin dezakegu.",
          iniciotxt1: "Kosmeren Semeak, aintzineko tradizioa mantenduz jarraitzen du artisau erako okigintzan, ama-masa, ura, gatza, legamia eta kalitatezko urunak erabilita",
          telefono: "Telefonoa: 94 615 61 45",
          productos1: "Kosmeren Semeak, ogigintzan aukera anitzak eskaintzen ditu. Jarri zaitez gurekin kontaktuan inolako zalantzarik gabe.",
          siguiente: "Jarraian",
          anterior: "Ohia"
    },
    cas: {
          subtit1: "Tradición Panadera",
          menu1: "Inicio",
          menu2: "Historia",
          menu3: "Servicios",
          menu4: "Productos",
          menu5: "Contacto",
          menu6: "Pedidos",
          historia1: "Hijos de Kosme es una empresa del sector de la panadería, se trata de una empresa familiar. Esta empresa ha perdurado en el tiempo y se ha ido transmitiendo de generación en generación en la familia Elgezabal de Mungia.<br><br>Corría el año 1.931 cuando Kosme Elgezabal comenzó a trabajar de pinche de panadero. Así pasaron los años y tras consolidarse como panadero, se lanzó a formar su propio negocio. De ese modo, implementando su forma de trabajo en la panadería que creo, con el tiempo ha ido paso a paso transmitiéndose, en un primer lugar a sus hijos y después a sus nietos.<br><br>Desde un comienzo la empresa elaboraba el pan y lo repartía, primero en Mungia y sus zonas colindantes ampliándose el rango en los siguientes años hacia el resto de la provincia de Bizkaia. Esto conllevó en que la empresa se fue extendiendo, se comenzaron a abrir nuevas panaderías en distintas localidades de la provincia y se creó la Pastelería Eleizalde resultando en que se amplió su oferta de productos.<br><br>El prestigio del pan de Kosmeren Semeak ha perdurado en el boca a boca de las personas por tratarse de un producto destacado. La celebridad de nuestro genero ha sido consolidada gracias a la elaboración del mismo por nuestros maestros panaderos de manera artesanal utilizando masa madre, agua, sal, levadura y harina de primera calidad.<br><br>Por todos estos lances Kosmeren Semeak se ha convertido en lo que es hoy en día. Una empresa de panadería y pastelería que es referente en buena parte de Bizkaia.",
          servicios1: "Hijos de Kosme cuenta con una flota de furgonetas y diversas panaderías, donde se vende nuestro amplio surtido de panadería, pastelería y repostería artesanales. Ofreciendo de ese modo nuestros servicios en la zona de Bizkaia.<br><br> <b>Para Individuales</b><br>Si usted vive en una vivienda unifamiliar en alguna de nuestras zonas de reparto, nosotros les llevamos a su domicilio el pan.<br><br><b>Para empresas</b><br>Actualmente damos servicio a infinidad de bares y restaurantes, tiendas, empresas de catering, comedores escolares etc.<br>No dudéis en contactar con nosotros y nos encargaremos de cubrir vuestra necesidad.<br><br><b>Pastelería Eleizalde</b><br>Contamos con la Pastelería Eleizalde, ofreciendo de ese modo un servicio completo a nuestros clientes. Así podemos proporcionar todo tipo de productos de pastelería como pasteles, tartas, pastas etc.<br>También elaboramos productos de temporada como las trufas y turrones en navidad, roscón de reyes, tortas de San Blas, tostadas de carnaval, buñuelos y huesos de santo en todos los santos<br><br><b>¿Quiere vender nuestros productos?</b><br>También suministramos nuestros productos a diferentes panaderías, supermercados, tiendas y todo tipo de negocios para su venta, tanto de pan directo o precocido.<br>Incluso si es necesario les cedemos un horno.",
          iniciotxt1: "Con el mismo cariño que en sus inicios,  Kosmeren Semeak sigue elaborando sus panes de manera artesanal utilizando masa madre, agua, sal, levadura y harinas de primera calidad",
          telefono: "Teléfono: 94 615 61 45",
          productos1: "Kosmeren Semeak cuenta con una gran variedad de productos artesanales. Para conocer todos los productos no dude en contactar con nosotros.",
          siguiente: "Siguiente",
          anterior: "Anterior"
    }
}
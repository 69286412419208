<template>
  <div>
      <b-container fluid class="d-flex justify-content-center align-items-center mt-5">
          <b-row>
              <b-col>
                  <b-card style="max-width: 70rem; background-color: #F5F5F7; color: #6E6E73;  font-family: 'Allison', cursive; font-weight: 400;">
                      <!-- Título personalizado centrado -->
                      <template v-slot:header>
                          <div class="text-center" style="font-size: 42px;">{{menu4}}</div>
                      </template>
                      <b-card-text style="font-size: 18px; font-family: 'Cormorant', cursive;">
                          <span v-html="productos1"></span>
                      </b-card-text>
                      <b-carousel style="font-size: 18px; font-family: 'Cormorant', cursive;"
                        id="carousel-1"
                        v-model="slide"
                        :interval="4000"
                        controls
                        indicators
                        :label-next="siguiente" 
                        :label-prev="anterior" 
                        background="#ababab"
                        img-width="600"
                        img-height="400"
                      >
                        <!-- Deslizamientos del carrusel -->
                        <b-carousel-slide
                          v-for="(item, index) in images"
                          :key="index"
                          :img-src="item.src"
                          :img-alt="item.alt"
                        ></b-carousel-slide>
                      </b-carousel>
                      <!-- <b-button href="#" variant="primary">Ir a algún lugar</b-button> -->
                  </b-card>
              </b-col>
          </b-row>
      </b-container>
  </div>
</template>

<script>
    import store from '@/store.js'
    import {traduccion} from "@/traducciones.js"

    export default {
        data() {
            return {
                slide: 0, // Índice del slide actual
                images: [
                  { src: require('@/assets/prod1.jpg'), alt: 'Producto 1' },
                  { src: require('@/assets/prod2.jpg'), alt: 'Producto 2' },
                { src: require('@/assets/prod3.jpg'), alt: 'Producto 3' },
                { src: require('@/assets/prod4.jpg'), alt: 'Producto 4' },
                { src: require('@/assets/prod5.jpg'), alt: 'Producto 5' },
                { src: require('@/assets/prod6.jpg'), alt: 'Producto 6' },
                { src: require('@/assets/prod7.jpg'), alt: 'Producto 7' },
                { src: require('@/assets/prod8.jpg'), alt: 'Producto 8' },
                { src: require('@/assets/prod9.jpg'), alt: 'Producto 9' },
                ],
            };
        },
        computed: {
            productos1() {
              return traduccion[store.language].productos1;
            },
            menu4() {
              return traduccion[store.language].menu4;
            },
            siguiente() {
              return traduccion[store.language].siguiente;
            },
            anterior() {
              return traduccion[store.language].anterior;
            }
        }
    }
</script>

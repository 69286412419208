// store.js
import Vue from 'vue';

const store = new Vue({
  data: {
    language: 'cas'
  },
  methods: {
    setLanguage(lang) {
      this.language = lang;
    }
  }
});

export default store;


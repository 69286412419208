<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="dark" bg="dark" sticky>
      <b-navbar-brand href="#">
        
        <div class="logo-title-container">
          <img src="@/assets/logo.png" alt="Logo" height="100">
          <div>
            <span class="navbar-title">Kosmeren Semeak</span>
            <div class="navbar-subtitle">{{subtit1}}</div>
          </div>
        </div>
        
        
        
        
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="b-nav-item">
          <b-nav-item to="/">{{ menu1 }}</b-nav-item>
          <b-nav-item to="/historia">{{ menu2 }}</b-nav-item>
          <b-nav-item to="/servicios">{{ menu3 }}</b-nav-item>
          <b-nav-item to="/productos">{{ menu4 }}</b-nav-item>
          <b-nav-item to="/contacto">{{ menu5 }}</b-nav-item>
          <b-nav-item to="/acercade">{{ menu6 }}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      <!-- Selector de idioma -->
      <b-dropdown variant="secondary" right style="margin-right: 100px;">
          <template #button-content>
              <img :src="iconoLen" alt="Icono" width="24">
          </template>
          <b-dropdown-item href="#" @click="setLanguage('eus')">Euskera</b-dropdown-item>
          <b-dropdown-item href="#" @click="setLanguage('cas')">Castellano</b-dropdown-item>
      </b-dropdown>

      
    </b-navbar>
    <router-view></router-view>
  </div>
</template>

<style scoped>
  
  @import url('https://fonts.googleapis.com/css2?family=Allison&display=swap');
  
  .logo-title-container {
    display: flex;
    align-items: center; /* Centrar verticalmente */
  }
  
  .b-nav-item {
    font-size: 1.1em;
    margin-left: 20px;
  }
  
  .navbar-title, .navbar-subtitle {
    text-align: center;
  }
  
  .navbar-title {
    font-family: 'Allison', cursive;
    font-size: 2.7em;  /* Ajusta esto según tu preferencia */
    line-height: 1;
    margin-left: 10px;  /* Espacio entre el logo y el título */
  }
  
  .navbar-subtitle {
    text-align: center;
    font-family: 'Allison', cursive;
    font-size: 1.7em;
    line-height: 1;
    margin-left: 10px; 
  }
  
  .b-card {
    font-family: 'Cormorant', cursive;
  }
  
  
</style>


<script>
  import store from '@/store.js'
  import {traduccion} from "@/traducciones.js"
  
  export default {
    name: 'App',
  
    data() {
      return {
        iconoLen: require('@/assets/lenguaje.png'),
        subtit1: traduccion[store.language].subtit1,
        menu1: traduccion[store.language].menu1,
        menu2: traduccion[store.language].menu2,
        menu3: traduccion[store.language].menu3,
        menu4: traduccion[store.language].menu4,
        menu5: traduccion[store.language].menu5,
        menu6: traduccion[store.language].menu6
      };
    },
    
    methods: {
      setLanguage(lang) {
        store.setLanguage(lang);
        this.subtit1 = traduccion[lang].subtit1;
        this.menu1 = traduccion[lang].menu1;
        this.menu2 = traduccion[lang].menu2;
        this.menu3 = traduccion[lang].menu3;
        this.menu4 = traduccion[lang].menu4;
        this.menu5 = traduccion[lang].menu5;
        this.menu6 = traduccion[lang].menu6;
      }
    }
  }
</script>

import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Router from 'vue-router';

import './assets/global.css';

import InicioApp from './views/InicioApp.vue'
import AcercaDe from './views/AcercaDe.vue'
import Historia from './views/Historia.vue'
import Servicios from './views/Servicios.vue'
import Productos from './views/Productos.vue'
import Contacto from './views/Contacto.vue'

Vue.config.productionTip = false

// Configuración de Vue-Bootstrap
Vue.use(BootstrapVue);

// Configuración de Vue-Router
Vue.use(Router);

const router = new Router({
  routes: [
    { path: '/', component: InicioApp },
    { path: '/acercade', component: AcercaDe },
    { path: '/historia', component: Historia },
    { path: '/servicios', component: Servicios },
    { path: '/productos', component: Productos },
    { path: '/contacto', component: Contacto }
  ]
})

new Vue({
  render: h => h(App),
  router // añadir la configuración del router aquí
}).$mount('#app')


<template>
  <div>
      <b-container fluid class="d-flex justify-content-center align-items-center mt-5">
          <b-row>
              <b-col>
                  <b-card style="max-width: 70rem; background-color: #F5F5F7; color: #6E6E73;  font-family: 'Allison', cursive; font-weight: 400;">
                      <!-- Título personalizado centrado -->
                      <template v-slot:header>
                          <div class="text-center" style="font-size: 42px;">{{menu3}}</div>
                      </template>
                      <b-card-text style="font-size: 18px; font-family: 'Cormorant', cursive;">
                          <!-- Imagen flotante en la parte derecha -->
                          <img :src="imgSrc" alt="Pan de Kosme" style="max-width: 11rem; float: right; margin: 0 0 15px 15px; border-radius: 8px;">
                          <span v-html="servicios1"></span>
                      </b-card-text>
                      <!-- <b-button href="#" variant="primary">Ir a algún lugar</b-button> -->
                  </b-card>
              </b-col>
          </b-row>
      </b-container>
  </div>
</template>

<script>
    import image from '@/assets/espigas.jpg';
    import store from '@/store.js'
    import {traduccion} from "@/traducciones.js"

    export default {
        data() {
            return {
                  imgSrc: image
            };
        },
        computed: {
            servicios1() {
              return traduccion[store.language].servicios1;
            },
            menu3() {
              return traduccion[store.language].menu3;
            }
        }
    }
</script>

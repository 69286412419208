<template>
  <div>
      <b-container fluid class="d-flex justify-content-center align-items-center mt-5">
          <b-row>
              <b-col>
                  <b-card style="max-width: 70rem; background-color: #F5F5F7; color: #6E6E73;  font-family: 'Allison', cursive; font-weight: 400;">
                      <!-- Título personalizado centrado -->
                      <template v-slot:header>
                          <div class="text-center" style="font-size: 42px;">{{menu5}}</div>
                      </template>
                      <!-- Imagen en la mitad superior -->
                      <img :src="imgSrc" alt="Imagen Obrador" class="img-fluid">
                      
                      <!-- Mapa en la mitad inferior -->
                      <div ref="googleMap" style="height: 300px;"></div>
                      
                      <!--
                      <b-card-text style="font-size: 18px; font-family: 'Cormorant', cursive;">
                          <span v-html="servicios1"></span>
                      </b-card-text>
                        -->
                  </b-card>
              </b-col>
          </b-row>
      </b-container>
  </div>
</template>

<script>
    import image from '@/assets/obrador.jpg';
    import store from '@/store.js'
    import {traduccion} from "@/traducciones.js"
    
    

    export default {
        data() {
            return {

                imgSrc: image
            };
        },
        computed: {
            servicios1() {
              return traduccion[store.language].servicios1;
            },
            menu5() {
              return traduccion[store.language].menu5;
            },
            telefono() {
              return traduccion[store.language].telefono;
            }
        },
        
        async mounted() {
            await this.loadGoogleMapsAPI();
            this.$nextTick(() => {
                this.initMap();
            });
        },
          methods: {
            initGoogleMaps() {
                this.initMap();

            },
            async loadGoogleMapsAPI() {
                return new Promise((resolve, reject) => {
                    // Si ya se cargó, simplemente resuelve
                    if (window.google && window.google.maps) {
                        resolve();
                        return;
                    }
            
                    // Configura el callback que Google Maps espera
                    window.initGoogleMaps = this.initGoogleMaps;
            
                    // Crea un nuevo script
                    const script = document.createElement('script');
                    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCETz6pDdfPUuzZD0q1BySoKpLDoJRJiZY&callback=initGoogleMaps";
                    script.async = true;
                    script.defer = true;
            
                    // Evento onload para cuando el script se haya cargado y esté listo
                    script.onload = resolve;
            
                    // Evento onerror para manejar errores
                    script.onerror = (error) => {
                        // Limpiar el callback global en caso de error
                        delete window.initGoogleMaps;
                        reject(error);
                    };
            
                    // Agrega el script al documento
                    document.head.appendChild(script);
                });
            },

            initMap() {
                if (!this.$refs.googleMap) {
                    console.error('No se encuentra la referencia al mapa.');
                    return;
                }

                  // Configuración del mapa
                  const options = {
                    zoom: 15,
                    center: { lat: 43.338052600885995, lng: -2.862899994449584 }, // Sustituye por la latitud y longitud de tu dirección
                    disableDefaultUI: true
                  };
            
                  // Crear el mapa
                  const map = new window.google.maps.Map(this.$refs.googleMap, options);
            
                  // Marcador con la dirección
                  const marker = new window.google.maps.Marker({
                    position: { lat: 43.338052600885995, lng: -2.862899994449584 }, // Sustituye por la latitud y longitud de tu dirección
                    map: map,
                    title: 'Kosmeren Semeak'
                  });
                  
                  
                  const infoContent = `
                      <div style="font-size: 18px;">
                          Ildepontso Zarraga, 1, Mungia, Bizkaia<br>
                          ${this.telefono}
                      </div>
                  `;
            
                  // Ventana de información con la dirección
                  const infoWindow = new window.google.maps.InfoWindow({
                    content: infoContent
                  });
                  
                  // Abre la ventana de información automáticamente
                  infoWindow.open(map, marker);
            
                  marker.addListener('click', function() {
                    infoWindow.open(map, marker);
                  });

            }
          }
    }
</script>
